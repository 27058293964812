import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import useUserStore from '../hooks/useUserStore'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useUserStore(state => state.user)

  return (
    <Route {...rest} render={props => {
      if (user) {
        return <Component {...props} />
      }
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }} />
  )
}

export const PublicRoute = ({ component: Component, ...rest }) => {
  const user = useUserStore(state => state.user)

  return (
    <Route {...rest} render={props => {
      if (user) {
        return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
      }
      return <Component {...props} />
    }} />
  )
}
