import React, { useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

const GooglePlacesSearch = props => {
  const autocompleteService = new window.google.maps.places.AutocompleteService()
  const geocoder = new window.google.maps.Geocoder()

  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const handleResultSelect = value => { 
    const { selectFunction } = props
    const result = value[0]
    if(!result) { return }

    geocoder.geocode({ 'placeId': result.source.place_id }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const latitude = results[0].geometry.location.lat()
        const longitude = results[0].geometry.location.lng()
        const name = result.source.description
        const google_place_id = result.source.place_id
        selectFunction({ name, latitude, longitude, google_place_id })
        setValue(result.source.description)
      }
    })
  }

  const handleSearchChange = value => {
    if(value.length > 0) {
      setLoading(true)
      setValue(value)
      autocompleteService.getPlacePredictions(
        { input: value },
        handleAutocompleteResult
      )
    } else {
      setValue(value)
    }
  }

  const handleAutocompleteResult = (predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      setSuggestions(predictions.map(prediction => {
        return {
          key: prediction.id,
          title: prediction.structured_formatting.main_text,
          description: prediction.structured_formatting.secondary_text,
          source: prediction
        }
      }))
      setLoading(false)
    }
  }

  return (
    <AsyncTypeahead
      id='locationTypeahead'
      value={value}
      options={suggestions}
      labelKey={opt => opt.source.description}
      onSearch={handleSearchChange}
      onChange={handleResultSelect}
      isLoading={loading}
      useCache={true}
    />
  )
}

export default GooglePlacesSearch