import React, { useState, useEffect, useCallback } from 'react'
import ReactMapGL, { StaticMap, Marker } from 'react-map-gl'
import {
  Card, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap'
import { Link } from 'react-router-dom'
import WebMercatorViewport from 'viewport-mercator-project'
import sizeMe from 'react-sizeme'
import moment from 'moment'
import './RideCard.css'

const RideCard = props => {
  const { ride } = props
  const { width } = props.size
  const { id, origin, destination, departure } = ride
  const driver = ride.riders.find((e) => e.driver === true)
  const calculateViewport = useCallback(() => {
    return new WebMercatorViewport({ height: width, width })
      .fitBounds([[origin.longitude, origin.latitude], [destination.longitude, destination.latitude]], {
        padding: 50,
        offset: [0, 0]
      })
  }, [width, destination, origin])
  const [viewport, setViewport] = useState(calculateViewport())

  useEffect(() => {
    setViewport(calculateViewport)
  }, [width, calculateViewport])

  return (
    <Card className="shadow border-0" key={`ride=${id}`}>
        <StaticMap
          {...viewport}
          mapOptions={{
            style: 'mapbox://styles/willynogs/cjzrluwnu2tut1cuqnia5wr3b'
          }}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
          fitBounds={[[32.958984, -5.353521], [43.50585, 5.615985]]}
          onViewportChange={(viewport) => setViewport(viewport)}
        >
          <Marker latitude={origin.latitude} longitude={origin.longitude} offsetLeft={-20} offsetTop={-10}>
            <span role='img' aria-label='origin' className='map-marker'>📍</span>
          </Marker>
          <Marker latitude={destination.latitude} longitude={destination.longitude} offsetLeft={-20} offsetTop={-10}>
            <span role='img' aria-label='destination' className='map-marker'>📍</span>
          </Marker>
      </StaticMap>
      <CardBody>
        <h6 className="text-primary text-uppercase">
          <i>From:</i>
          <br />
          {origin.name}
          <br />
          <i>To:</i>
          <br />
          {destination.name}
        </h6>
        <p className="description mt-3">
          <strong>Departure: </strong> <span className='date'>{moment(departure).format('MMMM Do YYYY, h:mm A')}</span>
          <br />
          <strong>Driver: </strong> {driver.user.name}
          <br />
          <strong>Seats Left: </strong> N/A
          <br />
          <strong>Price</strong> <i>${ride.rider_cost || 'xx.xx'}</i>
        </p>
        <Button tag={Link} to={`/ride/${ride.id}`} block>View Ride</Button>
      </CardBody>
    </Card>
  )
}

export default sizeMe()(RideCard)