import React from 'react'
import { Card } from 'reactstrap'

const GradientSection = props => {
  return (
    <section className="section section-lg pb-5 pt-0">
      <Card className="bg-gradient-primary shadow-lg border-0">
        <div className="p-3">
          {props.children}
        </div>
      </Card>
    </section>
  )
}

export default GradientSection