import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import useAxios from 'axios-hooks'

const CheckoutButton = props => {
  const { ride } = props
  const stripe = window.Stripe('pk_test_R3h1r2Rtqu7QrYsBYsGmD3sB00Wj4Ga8DR')

  const [{ data, loading }, createStripeSession] = useAxios({
    method: 'POST',
    url: '/checkouts/new',
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json'
    },
    data: {
      id: ride.id
    }
  }, { manual: true })

  useEffect(() => {
    if(data && !loading) {
      stripe.redirectToCheckout({ sessionId: data.session_id }).then(result => {
        console.log(result.error.message)
      })
    }
  }, [data, loading, stripe])

  return (
    <Button className='mt-3' onClick={createStripeSession}>Join Ride</Button>
  )
}

export default CheckoutButton