import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import ReactDatetime from 'react-datetime'
import { Redirect } from 'react-router-dom'
import useAxios from 'axios-hooks'
import _ from 'lodash'
import GooglePlacesSearch from '../GooglePlacesSearch/GooglePlacesSearch'
import useUserStore from '../../hooks/useUserStore'
import Authenticated from '../../layouts/Authenticated/Authenticated'
import GradientSection from '../GradientSection'
import './NewRide.css'

const NewRide = () => {
  const user = useUserStore(state => state.user)
  const [origin, setOrigin] = useState(null)
  const [destination, setDestination] = useState(null)
  const [vin, setVin] = useState('')
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [departure, setDeparture] = useState(null)

  const [{ data: userVehicles }, fetchUserVehicles] = useAxios({
    method: 'GET',
    url: `/user_vehicles/${_.get(user, 'id')}`,
    headers: {
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json'
    },
    withCredentials: true
  }, { manual: true })

  const [{ data: newVehicleData }, createVehicle] = useAxios({
    method: 'POST',
    url: '/vehicles',
    withCredentials: true,
    data: { vin }
  }, { manual: true })

  const [{ data }, createRide] = useAxios({
    method: 'POST',
    url: '/rides',
    withCredentials: true,
    data: {
      origin, destination, vehicle_id: selectedVehicle, departure, driver_id: _.get(user, 'id')
    }
  }, { manual: true })

  useEffect(() => {
    if(user && !userVehicles) { fetchUserVehicles() }
    if(userVehicles) { setVehicles(userVehicles) }
    if(newVehicleData && newVehicleData.success) { setVehicles([...vehicles, newVehicleData.vehicle]) }
  }, [user, fetchUserVehicles, userVehicles, newVehicleData, vehicles]) 

  if(data && data.success) {
    return (
      <Redirect to={`/ride/${data.ride.id}`} />
    )
  }

  return (
    <Authenticated>
      <Container className='pt-5'>
        <GradientSection>
          <Row className="align-items-center">
            <Col lg="8">
              <h3 className="text-white mb-0">
                <span role='img' aria-label='oncoming car'>🚘</span>&nbsp;Create a new ride!
                <ul className="lead text-white mt-3">
                  <li>Use the form below to create a ride you are taking.</li>
                  <li>Once you submit this form, other will be able to join your ride!</li>
                </ul>
              </h3>
            </Col>
          </Row>
        </GradientSection>
        <Card className="shadow p-3">
          <Form>
            <FormGroup>
              <Label>Departure</Label>
              <ReactDatetime onChange={setDeparture} />
            </FormGroup>
            <FormGroup>
              <Label>Origin</Label>
              <GooglePlacesSearch value={origin} selectFunction={setOrigin} />
            </FormGroup>
            <FormGroup>
              <Label>Destination</Label>
              <GooglePlacesSearch value={destination} selectFunction={setDestination} />
            </FormGroup>
            <FormGroup>
              <Label>Add New Vehicle</Label>
              <Input value={vin} placeholder='Vehicle VIN' onChange={e => setVin(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Button onClick={createVehicle}>Create Vehicle</Button>
            </FormGroup>
            <FormGroup>
              <Label for="vehicle">Vehicle</Label>
              <Input type="select" name="vehicleSelect" id="vehicle" defaultValue={null} onChange={e => setSelectedVehicle(e.target.value)}>
                <option value={null}>Please Select a Vehicle</option>
                {vehicles.map(v => (
                  <option key={v.id} value={v.id}>{v.year} {v.make} {v.model} - {v.seats} seats</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Button onClick={createRide}>Create Ride</Button>
            </FormGroup>
          </Form>
        </Card>
      </Container>
    </Authenticated>
  )
}

export default NewRide