import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from './RouteWrappers'
import Splash from '../components/Splash'
import Login from '../components/Login/Login'
import Signup from '../components/Signup/Signup'
import Home from '../components/Home/Home'
import Rides from '../components/Rides/Rides'
import Ride from '../components/Ride/Ride'
import NewRide from '../components/NewRide/NewRide'
import MyAccount from '../components/MyAccount/MyAccount'

const AppRouter = props => {
  return (
    <Router>
      <PublicRoute path="/" exact component={Splash} />
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/signup" component={Signup} />
      <Route exact path="/rides" component={Rides} />
      <Route exact path="/rides/new" component={NewRide} />
      <PrivateRoute path="/home" component={Home} />
      <PrivateRoute path="/ride/:id" component={Ride} />
      <PrivateRoute path="/my-account" component={MyAccount} />
    </Router>
  )
}

export default AppRouter