import React from 'react'
import Header from '../../components/Header'
import './Authenticated.css'

const Authenticated = props => {
  return (
    <React.Fragment>
      <Header />
      {props.children}
    </React.Fragment>
  )
}

export default Authenticated