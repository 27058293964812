import React, { useState } from 'react'
import { Button, Container, Card, Col, Row, Form, FormGroup, Label, Input } from 'reactstrap'
import useAxios from 'axios-hooks'

const DriverSettings = props => {
  const { ride } = props
  const [seatsAvailable, setSeatsAvailable] = useState(ride.seats_left)
  const [riderCost, setRiderCost] = useState(ride.rider_cost)

  const [{ data, loading, error}, updateRide] = useAxios({
    method: 'PUT',
    url: `/rides/${ride.id}`,
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json'
    },
    data: {
      seats_available: seatsAvailable,
      rider_cost: riderCost,
    },
  }, { manual: true })

  return (
    <Card className="card-profile shadow mb-5">
      <div className="p-4">
        <Row>
          <Col md='4' sm='12' className='text-center'>
            <h2>${ride.total_cost}</h2>
            <h4>Total Estimated Cost</h4>
          </Col>
          <Col md='4' sm='12' className='text-center'>
            <h2>{ride.seats_available}</h2>
            <h4>Max Seats Available</h4>
          </Col>
          <Col md='4' sm='12' className='text-center'>
            <h2>${ride.max_allowable_cost}</h2>
            <h4>Max Allowable Cost</h4>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md='6' sm='12'>
              <FormGroup>
                <Label>Seats Available</Label>
                <Input type='numeric' placeholder='Seats Available' value={seatsAvailable} onChange={e => setSeatsAvailable(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md='6' sm='12'>
              <FormGroup>
                <Label>Cost per Rider ($)</Label>
                <Input type='text' placeholder='Cost per Rider' value={riderCost} onChange={e => setRiderCost(e.target.value)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Button block onClick={updateRide}>Update Ride</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  )
}

export default DriverSettings