import React from "react"
import useAxios from 'axios-hooks'
import { useParams } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Spinner
} from 'reactstrap'
import Authenticated from '../../layouts/Authenticated/Authenticated'
import useUserStore from '../../hooks/useUserStore'
import CheckoutButton from '../CheckoutButton'
import RideMap from '../RideMap'
import DriverSettings from '../DriverSettings'
import GradientSection from '../GradientSection'
import './Ride.css'

const Ride = props => {
  const { id } = useParams()
  const user = useUserStore(state => state.user)

  const [{ data: ride, loading }] = useAxios({
    url: `${process.env.REACT_APP_API_URL}/rides/${id}`,
    method: 'GET',
    withCredentials: true
  })

  const driver = loading ? null : ride.riders.find(rider => rider.driver)

  return (
    <Authenticated>
      {loading ? (
        <Spinner size="sm" color="secondary" />
      ) : (
        <Container className='pt-5'>          
          <GradientSection>
            <Row className="align-items-center">
              <Col lg="8">
                <h3 className="text-white mb-0">
                  <span role='img' aria-label='hand waving'>🚘</span>&nbsp;Check out this ride!
                  <ul className="lead text-white mt-3">
                    <li>From {ride.origin.name}</li>
                    <li>To {ride.destination.name}</li>
                    <li>For the low cost of just ${ride.rider_cost}</li>
                    <CheckoutButton ride={ride} />
                  </ul>
                </h3>
              </Col>
            </Row>
          </GradientSection>
          <RideMap ride={ride} />
          <DriverSettings ride={ride} />
        </Container>
      )}
    </Authenticated>
  )
}

export default Ride
