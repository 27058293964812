import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { Button, Card, Container, Col, Form, FormGroup, Label, Input, Row } from 'reactstrap'
import RideCard from '../RideCard/RideCard'
import GooglePlacesSearch from '../GooglePlacesSearch/GooglePlacesSearch'
import GradientSection from '../GradientSection'

const RideSearch = () => {
  const [rides, setRides] = useState([])
  const [origin, setOrigin] = useState('')
  const [destination, setDestination] = useState('')
  const [originRadius, setOriginRadius] = useState(20)
  const [destinationRadius, setDestinationRadius] = useState(20)

  const [{ data, loading }, search] = useAxios({
    method: 'POST',
    url: '/rides/search',
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json'
    },
    data: {
      origin: [origin.latitude, origin.longitude],
      originRadius,
      destination: [destination.latitude, destination.longitude],
      destinationRadius,
    },
  }, { manual: true })

  useEffect(() => {
    if(data && !loading) {
      setRides(data)
    }
  }, [data, loading, setRides])

  return (
    <Container className='pt-5'>
      <GradientSection>
        <Row className="align-items-center">
          <Col lg="8">
            <h3 className="text-white mb-0">
              <span role='img' aria-label='magnifying glass'>🔍</span>&nbsp;Find a ride here!
              <ul className="lead text-white mt-3">
                <li>Use the form below to search for upcoming rides.</li>
                <li>If you're search doesn't return any results, try a different query!</li>
              </ul>
            </h3>
          </Col>
        </Row>
      </GradientSection>
      <Card className="shadow p-3">
        <Form>
          <FormGroup>
            <Label>Origin</Label>
            <GooglePlacesSearch value={origin} selectFunction={setOrigin} />
          </FormGroup>
          <FormGroup>
            <Label>Origin Radius (mi)</Label>
            <Input type='text' placeholder='Origin Radius (mi)' value={originRadius} onChange={e => setOriginRadius(e.target.value)}></Input>
          </FormGroup>
          <FormGroup>
            <Label>Destination</Label>
            <GooglePlacesSearch value={destination} selectFunction={setDestination} />
          </FormGroup>
          <FormGroup>
            <Label>Destination Radius (mi)</Label>
            <Input type='text' placeholder='Destination Radius (mi)' value={destinationRadius} onChange={e => setDestinationRadius(e.target.value)}></Input>
          </FormGroup>
          <FormGroup>
            <Button onClick={search}>Search</Button>
          </FormGroup>
        </Form>
      </Card>
      <Row className="row-grid pt-5 pb-5">
        {rides.map(ride => (
          <Col lg="4" key={ride.id}>
            <RideCard ride={ride} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default RideSearch