import React from 'react'
import useUserStore from '../../hooks/useUserStore'
import Authenticated from '../../layouts/Authenticated/Authenticated'

const MyAccount = () => {
  const user = useUserStore(state => state.user)

   return (
     <Authenticated>
      <p>Coming Soon...</p>
     </Authenticated>
   )
}

export default MyAccount

// import { Button, Card, Divider, Form, Header, Loader, Statistic } from 'semantic-ui-react'
// import Authenticated from '../../layouts/Authenticated/Authenticated'
// import { UserContext } from '../../contexts/UserContext'
// import { resetUserPassword } from '../../api/users'
// import { getUserTransactions } from '../../api/braintree'
// import './MyAccount.css'

// class MyAccount extends Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       loading: true,
//       transactions: [],
//       password: '',
//       passwordConfirmation: ''
//     }
//   }

//   static contextType = UserContext

//   resetPassword = () => {
//     const { password, passwordConfirmation } = this.state
//     const { id } = this.context.state.user
//     resetUserPassword(id, password, passwordConfirmation)
//   }

//   resetPasswordEnabled = () => {
//     const { password, passwordConfirmation } = this.state
//     return (password !== '') && (password === passwordConfirmation)
//   }

//   setPassword = (password) => {
//     this.setState({ password })
//   }

//   setPasswordConfirmation = (passwordConfirmation) => {
//     this.setState({ passwordConfirmation })
//   }

//   componentDidMount() {
//     const { id } = this.context.state.user
//     getUserTransactions(id).then(json => {
//       console.log(json)
//       this.setState({ loading: false, transactions: json })
//     })
//   }

//   render() {
//     const allowResetPassword = !this.resetPasswordEnabled()
//     const { transactions } = this.state

//     return (
//       <Authenticated>
//         <Header as='h1' textAlign='center'>My Account</Header>
//         <Header as='h3'>Payout Available</Header>
//         <Divider />

//         <div className='flex-split'>
//           <Statistic>
//             <Statistic.Value>$0.00</Statistic.Value>
//           </Statistic>
//           <Button>Cash Out</Button>
//         </div>
        
//         <Header as='h3'>Transaction History</Header>
//         <Divider />
//         <Card.Group>
//           {transactions.map(transaction => (
//             <Card fluid>
//               <Card.Content>
//                 <Statistic size='small'>
//                   <Statistic.Value>${transaction.amount}</Statistic.Value>
//                 </Statistic>
//               </Card.Content>
//             </Card>
//           ))}
//         </Card.Group>

//         <Header as='h3'>Change Password</Header>
//         <Divider />
//         <Form>
//           <Form.Field>
//             <label>New Password</label>
//             <input placeholder='New Password' type='password' onChange={(password) => this.setPassword(password.target.value)} />
//           </Form.Field>
//           <Form.Field>
//             <label>New Password Confirmation</label>
//             <input placeholder='New Password Confirmaton' type='password' onChange={(passwordConfirmation) => this.setPasswordConfirmation(passwordConfirmation.target.value)} />
//           </Form.Field>
//           <Button type='submit' disabled={allowResetPassword} onClick={() => this.resetPassword()}>Reset Password</Button>
//         </Form>
//       </Authenticated>
//     )
//   }
// }

// export default MyAccount