import React, { useState, useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { Button, Card, Container, Row, Col  } from 'reactstrap'
import useUserStore from '../../hooks/useUserStore'
import RideCard from '../RideCard/RideCard'
import RideCardv2 from '../RideCardv2'
import GradientSection from '../GradientSection'
import Authenticated from '../../layouts/Authenticated/Authenticated'

const Home = () => {
  const [futureRides, setFutureRides] = useState([])
  const user = useUserStore(state => state.user)

  const [{ data: futureRideData }] = useAxios({
    url: `/user_rides/${user.id}?time_scope=future`,
    method: 'GET',
    withCredentials: true
  })

  useEffect(() => {
    if(futureRideData) { setFutureRides(futureRideData) }
  }, [futureRideData])

  return (
    <Authenticated>
      <Container className='pt-5'>
        <GradientSection>
          <Row className="align-items-center">
            <Col lg="8">
              <h3 className="text-white mb-0">
                <span role='img' aria-label='hand waving'>👋</span>&nbsp;Hello, {user.name}!
                <ul className="lead text-white mt-3">
                  <li>You have {futureRides.length} rides coming up.</li>
                  <li>There are no events happening near you right now.</li>
                </ul>
              </h3>
            </Col>
          </Row>
        </GradientSection>
        <h2>Your Upcoming Rides</h2>
        <hr />
        {futureRides.length > 0 ? (
          <Row className="row-grid pb-5">
            {futureRides.map(ride => {
              return (
                <Col lg="12" className='pb-5' key={ride.id}>
                  <RideCardv2 ride={ride} />
                </Col>
              )
            })}
          </Row>) : (
          <h3>You don't have any rides coming up!</h3>
        )}
      </Container>
    </Authenticated>
  )
}

export default Home