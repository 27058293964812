import React from 'react'
import RideSearch from '../RideSearch'
import Authenticated from '../../layouts/Authenticated/Authenticated'

const Rides = () => {
  return (
    <Authenticated>
      <RideSearch />
    </Authenticated>
  )
}

export default Rides