import React, { useEffect } from 'react'
import { StripeProvider } from 'react-stripe-elements'
import AppRouter from './routes/AppRouter'
import useUserStore from './hooks/useUserStore'
import './axios_config'
import './assets/argon-design-system-react.css'

const App = () => {
  const { loading, refresh } = useUserStore(state => ({ loading: state.loading, refresh: state.refresh }))

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <StripeProvider apiKey='pk_test_R3h1r2Rtqu7QrYsBYsGmD3sB00Wj4Ga8DR'>
      <div className="App">
        {loading ? <p>Loading...</p> : <AppRouter />}
      </div>
    </StripeProvider>
  )
}

export default App
