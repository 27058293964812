import React from 'react'
import classnames from 'classnames'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap'
import { FaDollarSign, FaGlobeAmericas, FaUsers, FaRoad, FaQuestion } from 'react-icons/fa'
import Header from '../Header'
import './index.css'

const Splash = props => {
  return (
    <>
      <Header />
      <main>
        <div className="position-relative">
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="8">
                    <h1 className="display-3 text-white">
                      Slugcar{" "}
                      <span>a new way to ride</span>
                    </h1>
                    <p className="lead text-white">
                      Affordable, long-distance ride-sharing.
                      See the world, make new friends.
                    </p>
                    <div className="btn-wrapper">
                      <Button
                        className="btn-icon mb-3 mb-sm-0"
                        color="info"
                        href="/rides"
                      >
                        <span className="btn-inner--text">Find a Ride</span>
                      </Button>
                      <Button
                        className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                        color="default"
                        href="/rides/new"
                      >
                        <span className="btn-inner--text">
                          Offer a Ride
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
        <section className="section pt-lg-0 mt--200">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row className="row-grid">
                  <Col lg="3">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                          <FaDollarSign className='callout-icon' />
                        </div>
                        <h6 className="text-primary text-uppercase">
                          Save Money
                        </h6>
                        <p className="description mt-3">
                          Whether you're giving or getting a ride, you'll save money!
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                          <FaGlobeAmericas className='callout-icon' />
                        </div>
                        <h6 className="text-success text-uppercase">
                          Help the Planet
                        </h6>
                        <p className="description mt-3">
                          Ride-sharing lowers your carbon footprint!
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-info rounded-circle mb-4">
                          <FaUsers className='callout-icon' />
                        </div>
                        <h6 className="text-info text-uppercase">
                          Meet new People
                        </h6>
                        <p className="description mt-3">
                          Make new friends on your way to your destination!
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                          <FaRoad className='callout-icon' />
                        </div>
                        <h6 className="text-warning text-uppercase">
                          Discover New Places
                        </h6>
                        <p className="description mt-3">
                          SlugCar makes it more affordable than ever to see the world.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section className='section'>
          <Container>
              <Row>
                <Col className="text-center" md="12">
                  <h3>
                    <strong>
                      <i>Slug·ging (verb): The practice of forming ad hoc, informal carpools.</i>
                    </strong>
                  </h3>
                </Col>
              </Row>
          </Container>
        </section>

        <section className='section bg-gradient-default'>
          <Container>
            <Row>
              <Col lg="12" className='mb-3'>
                <h2 className="display-3 text-white text-center">Check out a few of our affordable options!</h2>
              </Col>
              <Col lg="4">
                <Card className="shadow border-0">
                  <CardBody className="py-3">
                    <h3>$25</h3>
                    DC to NYC
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="shadow border-0">
                  <CardBody className="py-3">
                    <h3>$25</h3>
                    San Francisco to Los Angeles
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="shadow border-0">
                  <CardBody className="py-3">
                    <h3>$25</h3>
                    NYC to Boston
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-1" md="12">
                <div className="pr-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <FaQuestion />
                  </div>
                  <h3>What is Slugcar?</h3>
                  <p>
                    SlugCar offers an innovative and fun way for travelers to reach their next destination. Through safely connecting passengers and drivers, SlugCar effectively reduces travel costs, enables schedule and destination flexibility, connects likeminded travelers, and reduces emissions.

                    Whether you are traveling to a music festival, your hometown, a weekend getaway, a hike upstate, back to school, or anything in between; SlugCar will get you there.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-0">
          <Container>
            <Card className="bg-gradient-warning shadow-lg border-0">
              <div className="p-5">
                <Row className="align-items-center">
                  <Col lg="8">
                    <h3 className="text-white">
                      Still not sure?
                    </h3>
                    <p className="lead text-white mt-3">
                      Read our faqs for an in depth look at what Slugcar is and how it can help you get to where you want to go, or subsidize the cost of a trip you're already taking.
                    </p>
                  </Col>
                  <Col className="ml-lg-auto" lg="3">
                    <Button
                      block
                      className="btn-white"
                      color="default"
                      href="#"
                      size="lg"
                    >
                      To FAQS
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </section>
        <section className="section section-lg bg-gradient-default">
          <Container className="pt-lg pb-300">
            <Row className="text-center justify-content-center">
              <Col lg="10">
                <h2 className="display-3 text-white">Interested in Helping Out?</h2>
                <p className="lead text-white">
                  While Slugcar is still in beta, we are actively looking for investors. Think you might be interested? Reach out using the form below!
                </p>
              </Col>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg pt-lg-0 section-contact-us">
          <Container>
            <Row className="justify-content-center mt--300">
              <Col lg="8">
                <Card className="bg-gradient-secondary shadow">
                  <CardBody className="p-lg-5">
                    <h4 className="mb-1">Ready to join the team?</h4>
                    <p className="mt-0">
                      Fill out the form below and someone will reach out to you within 72 hours.
                    </p>
                    <FormGroup
                      className={classnames("mt-5", {
                        focused: false
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-user-run" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Your name"
                          type="text"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: false
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email address"
                          type="email"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Input
                        className="form-control-alternative"
                        cols="80"
                        name="name"
                        placeholder="Type a message..."
                        rows="4"
                        type="textarea"
                      />
                    </FormGroup>
                    <div>
                      <Button
                        block
                        className="btn-round"
                        color="default"
                        size="lg"
                        type="button"
                      >
                        Send Message
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Splash