import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap'
import useUserStore from '../../hooks/useUserStore'
import logo from '../../assets/slugcar.png'

const Header = () => {
  const { user, logout } = useUserStore(state => ({ user: state.user, logout: state.logout })) 

  const collapsedNavItems = () => {
    if(user) {
      return (
        <>
          <NavItem className="d-lg-none ml-lg-4">
            <NavLink href='/rides'>
              Find a Ride
            </NavLink>
          </NavItem>
          <NavItem className="d-lg-none ml-lg-4">
            <NavLink href='/rides/new'>
              Create a Ride
            </NavLink>
          </NavItem>
          <NavItem className="d-lg-none ml-lg-4">
            <NavLink href='/my-account'>
              My Account
            </NavLink>
          </NavItem>
          <NavItem className="d-lg-none ml-lg-4">
            <NavLink href='#' onClick={logout}>
              Logout
            </NavLink>
          </NavItem>
        </>
      )
    }

    return (
      <>
        <NavItem className="d-lg-none ml-lg-4">
          <NavLink href='/signup'>
            Sign Up
          </NavLink>
        </NavItem>
        <NavItem className="d-lg-none ml-lg-4">
          <NavLink href='/login'>
            Log In
          </NavLink>
        </NavItem>
      </>
    )
  }

  const expandedNavItems = () => {
    if(user) {
      return (
        <>
          <NavItem className="d-none d-lg-block">
            <NavLink href='/rides'>
              Find a Ride
            </NavLink>
          </NavItem>
          <NavItem className="d-none d-lg-block">
            <NavLink href='/rides/new'>
              Create a Ride
            </NavLink>
          </NavItem>
          <NavItem className="d-none d-lg-block">
            <NavLink href='/my-account'>
              My Account
            </NavLink>
          </NavItem>
          <NavItem className="d-none d-lg-block">
            <NavLink href='#' onClick={logout}>
              Logout
            </NavLink>
          </NavItem>
        </>
      )
    }

    return (
      <>
        <NavItem className="d-none d-lg-block ml-lg-4">
          <Button
            className="btn-info btn-icon"
            color="info"
            href="/signup"
          >
            <span className="nav-link-inner--text ml-1">
              Sign Up
            </span>
          </Button>
        </NavItem>
        <NavItem className="d-none d-lg-block ml-lg-4">
          <Button
            className="btn-neutral btn-icon"
            color="default"
            href="/login"
          >
            <span className="nav-link-inner--text ml-1">
              Log In
            </span>
          </Button>
        </NavItem>
      </>
    )
  }

  return (
    <header className="header-global">
      <Navbar
        className="navbar-main navbar-dark bg-primary navbar-expand-lg"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            <img
              alt="..."
              src={logo}
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar_global">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar_global">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={logo}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar_global">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>

            <Nav className="navbar-nav-hover align-items-lg-center" navbar>
              {collapsedNavItems()}
            </Nav>

            <Nav className="align-items-lg-center ml-lg-auto" navbar>
              {expandedNavItems()}
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header