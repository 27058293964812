import React, { useState, useCallback, useEffect } from 'react'
import {
  Card,
} from 'reactstrap'
import ReactMapGL, { Marker } from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'

const RideMap = props => {
  const { ride } = props
  const [viewport, setViewport] = useState(null)

  const calcViewport = useCallback(() => {
    const { origin, destination } = ride

    return new WebMercatorViewport({
      width: 500,
      height: 500
    }).fitBounds(
      [
        [origin.longitude, origin.latitude],
        [destination.longitude, destination.latitude]
      ],
      {
        padding: 50,
        offset: [0, 0]
      }
    )
  }, [ride])
  
  useEffect(() => {
    setViewport(calcViewport())
  }, [calcViewport])

  return (
    <Card className="card-profile shadow mb-5">
      <div className="p-4">
          <section className="ride-map-header">
            <ReactMapGL
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
              {...viewport}
              mapOptions={{
                style: 'mapbox://styles/willynogs/cjzrluwnu2tut1cuqnia5wr3b'
              }}
              width="100%"
              onViewportChange={viewport => setViewport(viewport)}
            >
              <Marker
                latitude={ride.origin.latitude}
                longitude={ride.origin.longitude}
                offsetLeft={-20}
                offsetTop={-10}
              >
                <span role="img" aria-label="origin" className="map-marker">
                  📍
                </span>
              </Marker>
              <Marker
                latitude={ride.destination.latitude}
                longitude={ride.destination.longitude}
                offsetLeft={-20}
                offsetTop={-10}
              >
                <span
                  role="img"
                  aria-label="destination"
                  className="map-marker"
                >
                  📍
                </span>
              </Marker>
            </ReactMapGL>
          </section>
      </div>
    </Card>
  )
}

export default RideMap