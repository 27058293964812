import create from 'zustand'
import axios from 'axios'

const [useStore] = create(set => ({
  loading: true, // this should not default to true, but for now it will have to
  user: null,
  refresh: async () => {
    const response = await axios({
      method: 'GET',
      url: `/auth/refresh`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })

    const { data: { user = null }} = response
    set(state => ({...state, loading: false, user }))
  },
  signUp: async (name, email, password, password_confirmation) => {
    if(!name || !email || !password || !password_confirmation) return false

    try {
      const response = await axios({
        method: 'POST',
        url: `/auth/login`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { name, email, password, password_confirmation },
        withCredentials: true
      })

      if(response.status === 200) { set(state => ({ ...state, user: response.data.user })) }
      return response.status === 200 ? true : response
    } catch(e) {
      console.log(e)
    }
  },
  login: async (email, password) => {
    if(!email || !password) return false

    try {
      const response = await axios({
        method: 'POST',
        url: `/auth/login`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { email, password },
        withCredentials: true
      })

      const { status, data: { success, user } } = response
      if(status === 200 && success) {
        set(state => ({ ...state, user }))
      }
      return status === 200 ? true : response
    } catch(e) {
      console.log(e)
    }
  },
  logout: async () => {
    const response = await axios({
      method: 'DELETE',
      url: `/auth/logout`,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })

    if(response.status === 202) {
      set({ user: null })
    }
  },
}))

export default useStore
